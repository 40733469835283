<template>
  <div
    class="inline-flex items-stretch border-divider"
    :class="[
      {
        'bg-disabled': !hasBackgroundClass,
        'text-headline': !hasTextClass,
        rounded: !hasRadiusClass
      },
      sizeClasses,
      paddingClasses
    ]"
  >
    <div v-if="$slots.prefix" class="flex items-center">
      <slot name="prefix" />
    </div>
    <div v-if="$slots.default">
      <slot />
    </div>
    <div v-if="$slots.suffix" class="flex items-center">
      <slot name="suffix" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, useAttrs, useSlots } from "vue";

const slots = useSlots();

const props = withDefaults(
  defineProps<{ size?: "xs" | "sm" | "md" | "lg" }>(),
  { size: "sm" }
);

const attrs = useAttrs();

const hasBackgroundClass = computed(
  () => attrs.class && !!(attrs.class as string).includes("bg-")
);

const hasTextClass = computed(
  () => attrs.class && !!(attrs.class as string).includes("text-")
);

const hasRadiusClass = computed(
  () => attrs.class && !!(attrs.class as string).includes("rounded-")
);

const sizeClasses = computed(() => {
  switch (props.size) {
    case "xs":
      return "px-1.5 py-[3px] text-xxs uppercase font-semibold leading-none";
    case "sm":
      return "text-sm leading-5 gap-x-1";
    case "md":
      return "py-[3px] text-md leading-5 gap-x-2";
    case "lg":
      return "py-[5px] text-sm leading-5 gap-x-2";
    default:
      return "";
  }
});

const paddingClasses = computed(() => {
  let enabledClasses: Record<string, boolean> = {};

  const havePrefixSlotOnly = !!slots.prefix && !slots.suffix;
  const haveSuffixSlotOnly = !slots.prefix && !!slots.suffix;
  const haveBothOptionalSlots = !!slots.prefix && !!slots.suffix;
  const haveNoOptionalSlots = !slots.prefix && !slots.suffix;

  if (props.size === "sm") {
    enabledClasses = {
      "px-1.5": haveNoOptionalSlots,
      "pl-1 pr-1.5": havePrefixSlotOnly,
      "px-1": haveBothOptionalSlots,
      "pl-1.5 pr-1": haveSuffixSlotOnly
    };
  }

  if (props.size === "md" || props.size === "lg") {
    enabledClasses = {
      "px-3": haveNoOptionalSlots,
      "pl-2 pr-3": havePrefixSlotOnly,
      "pl-3 pr-2": haveSuffixSlotOnly,
      "px-2": haveBothOptionalSlots
    };
  }

  return enabledClasses;
});
</script>

<style scoped>
:deep() {
  svg {
    @apply inline-flex !important;
    margin-bottom: 0 !important;
  }
}
</style>
